import { AxiosHeaders } from 'axios';
export var isURLSearchParams = function (value) {
    return (typeof URLSearchParams !== 'undefined' && value instanceof URLSearchParams);
};
export var isFormData = function (value) {
    return typeof FormData !== 'undefined' && value instanceof FormData;
};
export var isPlainObject = function (value) {
    if (value == null) {
        return false;
    }
    var proto = Object.getPrototypeOf(value);
    return proto === null || proto === Object.prototype;
};
export var isTransformable = function (value) {
    return (Array.isArray(value) ||
        isPlainObject(value) ||
        isFormData(value) ||
        isURLSearchParams(value));
};
export var isAxiosHeaders = function (value) {
    if (value == null) {
        return false;
    }
    return value instanceof AxiosHeaders;
};
