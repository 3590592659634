import { __assign, __read, __spreadArray, __values } from "tslib";
import { createObjectTransformers } from './transformers';
import { isAxiosHeaders, isPlainObject } from './util';
export var createSnakeParamsInterceptor = function (options) {
    var snake = createObjectTransformers(options === null || options === void 0 ? void 0 : options.caseFunctions).snake;
    return function (config) {
        if (!(options === null || options === void 0 ? void 0 : options.ignoreParams) && config.params) {
            config.params = snake(config.params, options);
        }
        return config;
    };
};
export var createSnakeRequestTransformer = function (options) {
    var _a = createObjectTransformers(options === null || options === void 0 ? void 0 : options.caseFunctions), snake = _a.snake, header = _a.header;
    return function (data, headers) {
        overwriteHeadersOrNoop(headers, header, options, [
            'common',
            'delete',
            'get',
            'head',
            'post',
            'put',
            'patch',
        ]);
        return snake(data, options);
    };
};
export var createCamelResponseTransformer = function (options) {
    var camel = createObjectTransformers(options === null || options === void 0 ? void 0 : options.caseFunctions).camel;
    return function (data, headers) {
        overwriteHeadersOrNoop(headers, camel, options);
        return camel(data, options);
    };
};
var overwriteHeadersOrNoop = function (headers, fn, options, excludedKeys) {
    var e_1, _a, _b, _c;
    if ((options === null || options === void 0 ? void 0 : options.ignoreHeaders) ||
        (!isPlainObject(headers) && !isAxiosHeaders(headers))) {
        return;
    }
    try {
        for (var _d = __values(Object.entries(headers)), _e = _d.next(); !_e.done; _e = _d.next()) {
            var _f = __read(_e.value, 2), key = _f[0], value = _f[1];
            fn(value, __assign({ overwrite: true }, options));
            if ((excludedKeys || []).includes(key)) {
                continue;
            }
            if (isAxiosHeaders(headers)) {
                headers.delete(key);
                headers.set(Object.keys(fn((_b = {}, _b[key] = null, _b), options))[0], value, true);
            }
            else {
                delete headers[key];
                headers[Object.keys(fn((_c = {}, _c[key] = null, _c), options))[0]] = value;
            }
        }
    }
    catch (e_1_1) { e_1 = { error: e_1_1 }; }
    finally {
        try {
            if (_e && !_e.done && (_a = _d.return)) _a.call(_d);
        }
        finally { if (e_1) throw e_1.error; }
    }
};
export var applyCaseMiddleware = function (axios, options) {
    var _a, _b, _c;
    axios.defaults.transformRequest = __spreadArray([
        ((_a = options === null || options === void 0 ? void 0 : options.caseMiddleware) === null || _a === void 0 ? void 0 : _a.requestTransformer) ||
            createSnakeRequestTransformer(options)
    ], __read((Array.isArray(axios.defaults.transformRequest)
        ? axios.defaults.transformRequest
        : axios.defaults.transformRequest !== undefined
            ? [axios.defaults.transformRequest]
            : [])), false);
    axios.defaults.transformResponse = __spreadArray(__spreadArray([], __read((Array.isArray(axios.defaults.transformResponse)
        ? axios.defaults.transformResponse
        : axios.defaults.transformResponse !== undefined
            ? [axios.defaults.transformResponse]
            : [])), false), [
        ((_b = options === null || options === void 0 ? void 0 : options.caseMiddleware) === null || _b === void 0 ? void 0 : _b.responseTransformer) ||
            createCamelResponseTransformer(options),
    ], false);
    axios.interceptors.request.use(((_c = options === null || options === void 0 ? void 0 : options.caseMiddleware) === null || _c === void 0 ? void 0 : _c.requestInterceptor) ||
        createSnakeParamsInterceptor(options));
    return axios;
};
